.container {
  display: grid;
  grid-template-areas:
    'code observers'
    'players players'
    'play play';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
}

.code,
.observersCount {
  display: inline-flex;
  border: 4px solid var(--primaryColor);
  border-radius: 15px;
  background-color: var(--primaryColor);
  color: white;
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 600;

  &:before {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background-color: white;
    color: var(--primaryColor);
    text-transform: initial;
    font-size: 0.5em;
  }
}

.code {
  grid-area: code;
  justify-self: flex-start;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 20px;
  animation: appearLeft 0.2s;

  &:before {
    content: 'Room code';
    margin-right: 20px;
  }
}

.observersCount {
  grid-area: observers;
  justify-self: flex-end;
  flex-direction: row-reverse;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;
  animation: appearRight 0.2s;

  &:before {
    content: 'Observer(s)';
    margin-left: 20px;
  }
}

.observersIcon {
  display: flex;
  align-items: center;
}

.players {
  grid-area: players;
  justify-self: center;
  display: grid;
  grid-template-columns: minmax(300px, min-content);
  grid-auto-rows: min-content;
  gap: 20px;
  flex: 1;
  padding: 30px;
}

.player {
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 0 15px 2px #79777750;
  background-color: var(--secondaryColor);
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  animation: appearLeft 0.5s;
  animation-fill-mode: backwards;

  @for $i from 1 through 4 {
    &:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.2}s;
    }
  }
}

.playContainer {
  grid-area: play;
  justify-self: center;
}

@keyframes appearLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appearRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
