$size: 100px;

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: $size;
  height: $size;
  border: 4px solid var(--primaryColor);
  border-radius: 100%;
  box-sizing: border-box;
  background-color: white;
  color: var(--primaryColor);
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    .circleContainer {
      transform: scale(0.9);

      .circle {
        stroke-width: 20%;
        animation: rotate 2.5s linear infinite, dash 1s ease-in-out forwards;
        // animation-name: rotate, dash;
      }
    }
  }
}

.circleContainer {
  position: absolute;
  transition: 0.5s;
  border-radius: 100%;
}

.circle {
  fill: transparent;
  stroke: var(--primaryColor);
  stroke-dasharray: 0;
  stroke-width: 0;
  transform-origin: center;
  transition: 0.5s;
  //   animation-duration: 2.5s;
  //   animation-timing-function: ease-in-out;
  //   animation-direction: alternate;
  //   animation-iteration-count: infinite;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes dash {
  from {
    stroke-dasharray: 0;
  }
  to {
    stroke-dasharray: 15;
  }
}
