.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;

  @media screen and (min-height: 480px)  {
    flex-direction: column;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 220px;
  max-height: 300px;
  border-radius: 50px;
  padding-bottom: 30px;
  box-shadow: 0 0 15px 2px #79777750;
  box-sizing: border-box;
  overflow: hidden;
  background-color: white;
}

.tabBar {
  display: flex;
}

.tabButton {
  flex: 1;
  padding: 20px 0;
  box-shadow: inset 0 -3px 5px 0 #79777750;
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.activeTabButton {
  background-color: var(--primaryColor);
  color: white;
}

.tabContainer {
  padding: 20px 25px 0;
}

.codeTextInput {
  text-transform: uppercase;
}

.observerCheckbox {
  margin-top: 10px;
}

.gameTypeContainer {
  $size: 60px;

  display: grid;
  grid-template-columns: repeat(auto-fill, $size);
  grid-auto-rows: $size;
  gap: 10px;
}

.gameTypeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--secondaryColor);
  border-radius: 15px;
  padding: 5px;
  background-color: var(--secondaryColor);
  background-clip: content-box;
  cursor: pointer;
  transition: all 0.3s;

  img {
    max-width: 80%;
    max-height: 80%;
  }

  &:hover,
  &.selected {
    border-color: var(--primaryColor);
  }

  &.selected {
    background-color: var(--primaryColor);
  }
}
