@import-normalize;
@import 'colors.scss';

body {
  margin: 0;
  color: var(--primaryColor);
  font-family: 'Changa', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

input {
  outline: none;
  border: none;
  padding: 0;
  font-size: inherit;
}
