$width: 35px;
$height: 8px;

.container {
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: min-content;
  align-items: center;
  gap: 5px;
}

.input {
  width: $width;
  height: $height;
  appearance: none;
  outline: none;
  border: 2px solid var(--primaryColor);
  border-radius: 15px;
  padding: 3px;
  box-sizing: content-box;
  cursor: pointer;
  transition: background 0.3s;

  &:before {
    $size: $height;

    display: block;
    content: "";
    position: relative;
    height: $size;
    width: $size;
    border-radius: $size;
    background-color: var(--primaryColor);
    transition: width 0.5s;
  }

  &:hover {
    background-color: var(--secondaryColor60);
  }

  &:checked {
    &:before {
      width: 100%;
    }
  }
}

.label {
  color: var(--primaryColor);
  user-select: none;
  cursor: pointer;
}
