.container {
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  height: 80px;
  font-size: 20px;
  font-weight: 600;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  border: 2px solid var(--primaryColor);
  border-radius: 12px;
  box-sizing: border-box;
  background-color: var(--primaryColor);
  overflow: hidden;
}

.innerContainer,
.label,
.input {
  transition: 0.2s ease-out;
}

.label,
.input {
  padding: 0 10px;
}

.label {
  display: flex;
  align-items: center;
  min-height: 100%;
  background-color: white;
  $color: var(--primaryColor);
  color: $color;
}

.input {
  flex: 1;
  max-height: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
}

.isActive {
  .innerContainer {
    height: 100%;
  }

  .label {
    min-height: 0px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    background-color: var(--primaryColor);
    color: white;
    font-size: 0.8em;
  }

  .input {
    max-height: 100%;
  }
}

.container:not(.isActive) {
  .label {
     cursor: text;
  }
}
