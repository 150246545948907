$buttonSize: 40px;

.container {
  display: flex;
  position: fixed;
  top: 10px;
  left: 10px;
  transition: box-shadow 0.3s;
}

.settingsBtn,
.settingsCard {
  box-shadow: 0 0 15px 2px #79777750;
}

.settingsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $buttonSize;
  height: $buttonSize;
  border-radius: 100%;
  background-color: var(--primaryColor);
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    animation: rotate 3.5s linear infinite;
  }
}

.settingsCard {
  position: relative; 
  top: calc($buttonSize / 2);
  left: calc($buttonSize / -2);
  border-radius: 15px;
  padding: calc($buttonSize/ 2);
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.isOpen {
}
